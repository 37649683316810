import styled from "styled-components"

import { Link } from "components/ui/link"

export const MenuLink = styled(Link).attrs(props => {
  return {
    to: props.link || "/",
    state: props.state,
  }
})`
  position: relative;
  white-space: nowrap;
  color: inherit;
  text-decoration: none;

  ${props => {
    if (props.$removeFromDesktop) {
      return `
        @media (${props => props.theme.breakpoints.tabletUp}) {
          display: none;
        }
        @media (min-width: 900px) {
          display: none;
        }
      `
    }
    if (props.$removeFromMobile) {
      return `
        @media (${props => props.theme.breakpoints.tabletDown}) 
          display: none;
        }
        @media (max-width: 900px) {
          display: none;
        }
      `
    }
  }}
`
