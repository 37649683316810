import React from "react"

import { NavBar } from "components/modules/navbar"

export const Header = () => {
  return (
    <NavBar
      breakpoint={900}
      condenseLeft={false}
      condenseCenter={false}
      condenseRight={true}
    />
  )
}
