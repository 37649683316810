/**
 * dom-wrapper.js
 *
 * This component wraps the website and persists through page changes.
 */

import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { Helmet } from "react-helmet"
import { CookiesProvider } from "react-cookie"
import styled from "styled-components"
import { setConfig } from "react-hot-loader"

import { StatusPageEmbed } from 'components/ui/statuspage'
import { Rewardful } from "components/controller/rewardful"
import { Theme } from "utils/theme"
import { Snipcart } from "components/ui/snipcart"
import { SnipcartProvider } from "components/context/snipcart"
import { SnipcartMetadata } from "components/controller/snipcart-metadata"
import { Auth0ProviderWithHistory } from "components/controller/auth0-with-history"
import { StyleReset } from "utils/css-reset"
import { StyleGlobal } from "utils/css-global"
import { Header } from "./header"
import { Footer } from "./footer"
import { GDPRBanner } from "components/controller/gdpr-banner"

setConfig({ logLevel: "no-errors-please" })

/**
 * Main
 * styled-component and <main> tag that wraps the page content
 */
const Main = styled.main`
  min-height: calc(100vh - 72px - 262px);
`

const PageWrapper = styled.div`
  padding-top: 70px;
  max-height: calc(100vh - 70px);
`

export const DOMWrapper = ({ children }) => {
  if (typeof window === "undefined") {
    return (
      <>
        <Helmet
          title="Torchlight Press"
          meta={[
            {
              name: "viewport",
              content: "width=device-width, initial-scale=1.0",
            },
          ]}
        >
          <link rel="preconnect" href="https://app.snipcart.com" />
          <link rel="preconnect" href="https://cdn.snipcart.com" />
        </Helmet>
        <Theme>
          <StyleReset />
          <StyleGlobal />

          <PageWrapper>
            <Header title="Torchlight Press" />

            <Main>{children}</Main>

            <Footer />
          </PageWrapper>
        </Theme>
      </>
    )
  }

  return (
    <>
      <Helmet
        title="Torchlight Press"
        meta={[
          {
            name: "viewport",
            content: "width=device-width, initial-scale=1.0",
          },
        ]}
      >
        <link
          rel="stylesheet"
          href="https://cdn.snipcart.com/themes/v3.2.0/default/snipcart.css"
        />
        <link rel="preconnect" href="https://app.snipcart.com" />
        <link rel="preconnect" href="https://cdn.snipcart.com" />
      </Helmet>

      <Rewardful />

      <SnipcartProvider>
        <CookiesProvider>
          <Auth0ProviderWithHistory>
            <Theme>
              <StyleReset />
              <StyleGlobal />

              <PageWrapper>
                <Header title="Torchlight Press" />

                <Main>{children}</Main>

                <Footer />
                <Snipcart />
                <SnipcartMetadata />
                <StatusPageEmbed />
                <GDPRBanner />
              </PageWrapper>
            </Theme>
          </Auth0ProviderWithHistory>
        </CookiesProvider>
      </SnipcartProvider>
    </>
  )
}
