import React, { useState } from "react"

export const SnipcartContext = React.createContext()

export const SnipcartProvider = ({ children }) => {
  const [snipcartReady, setSnipcartReady] = useState()
  const [snipcartApi, setSnipcartApi] = useState()

  React.useEffect(() => {
    const listenSnipcart = () => {
      document.addEventListener("snipcart.ready", () => {
        setSnipcartReady(true)
        setSnipcartApi(window.Snipcart)
      })
    }

    if (window.Snipcart !== undefined) {
      setSnipcartReady(true)
      setSnipcartApi(window.Snipcart)
    } else {
      listenSnipcart()
    }
  }, [])

  return (
    <SnipcartContext.Provider value={{ snipcartReady, snipcartApi }}>
      {children}
    </SnipcartContext.Provider>
  )
}
