import React from "react"
import styled from "styled-components"

export const Cart = ({ showCartLabel = true }) => {
  return (
    <Wrapper>
      {showCartLabel && <CartLabel>Cart</CartLabel>}
      <CartIconWrapper>
        <CartIconLine1 />
        <CartIconLine2 />
        <CartIconContents className="snipcart-items-count">0</CartIconContents>
        <CartIconWheels />
      </CartIconWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const CartLabel = styled.span`
  margin-right: 5px;

  @media (max-width: ${props => props.theme.breakpoints.tablet}px) {
    display: none;
  }
`

const CartIconWrapper = styled.div`
  width: 50px;
  height: 50px;
  margin-right: -10px;
  position: relative;
  overflow: hidden;
`

const CartIconContents = styled.div`
  width: 50%;
  position: absolute;
  left: 22%;
  top: 10%;
  color: white;
  font-size: 1em;
  text-align: center;
`

const CartIconLines = styled.div`
  background-color: #c5bfb6;
`

const CartIconLine1 = styled(CartIconLines)`
  width: 15%;
  height: 7%;
  position: absolute;
  left: 8%;
  top: 25%;
  -webkit-transform: rotate(5deg);
  -moz-transform: rotate(5deg);
  -ms-transform: rotate(5deg);
  transform: rotate(5deg);
  border-bottom-left-radius: 35%;
`

const CartIconLine2 = styled(CartIconLines)`
  width: 35%;
  height: 7%;
  position: absolute;
  left: 6%;
  top: 40%;
  -webkit-transform: rotate(80deg);
  -moz-transform: rotate(80deg);
  -ms-transform: rotate(80deg);
  transform: rotate(80deg);

  &:before {
    content: "";
    width: 120%;
    height: 100%;
    position: absolute;
    left: 45%;
    top: -280%;
    -webkit-transform: rotate(-80deg);
    -moz-transform: rotate(-80deg);
    -ms-transform: rotate(-80deg);
    transform: rotate(-80deg);
    background-color: inherit;
  }

  &:after {
    content: "";
    width: 70%;
    height: 100%;
    position: absolute;
    left: 59%;
    top: -670%;
    background-color: inherit;
    -webkit-transform: rotate(40deg);
    -moz-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
    border-top-left-radius: 50%;
    border-bottom-left-radius: 25%;
  }
`

const CartIconWheels = styled(CartIconLines)`
  width: 12%;
  height: 12%;
  border-radius: 100%;
  position: absolute;
  left: 28%;
  bottom: 20%;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: inherit;
    border-radius: 100%;
    position: absolute;
    left: 200%;
    bottom: 0;
  }
`
