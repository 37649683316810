import React from "react"
import styled from "styled-components"

import { MenuLink as Link } from "components/modules/navbar/links/link"

const Wrapper = styled.div`
  color: white;
  background-color: ${props => props.theme.colors.purple};
  padding: 50px 50px;
  > * {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  @media (min-width: 700px) {
    padding: 50px 100px;
  }
`

const Title = styled.div`
  font-family: "Segoe UI", sans-serif;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
`

const DateDiv = styled.div`
  padding-top: 20px;
`

export const Footer = () => {
  return (
    <Wrapper>
      <Title>Site Map</Title>
      <div>
        <Link link="/affiliates">Affiliate Program</Link>
      </div>
      <div>
        <Link link="/legal/terms-of-service">Terms of Service</Link>
      </div>
      <div>
        <Link link="/legal/privacy-policy">Privacy Policy</Link>
      </div>
      <DateDiv>© Torchlight Press {new Date().getFullYear()}</DateDiv>
    </Wrapper>
  )
}
