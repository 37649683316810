import MarkdownIt from "markdown-it"

export const isBrowser = typeof window !== "undefined"

export function deepFind(obj, path, defaultValue = undefined) {
  var paths = path.split("."),
    current = obj,
    i

  for (i = 0; i < paths.length; ++i) {
    if (current[paths[i]] === undefined) {
      return defaultValue
    } else {
      current = current[paths[i]]
    }
  }
  return current
}

export function markdownToHtml(text) {
  var md = MarkdownIt({
    html: true,
  })

  let htmlChunks = text.match(/(<[^/]+<\/[a-zA-Z]+>)/gm)
  let htmlArray = htmlChunks.map(chunk => {
    let tags = chunk.match(/<([a-zA-Z/ ]+)>/gm)
    if (tags.length === 2) {
      chunk = chunk.replace(tags[0], "").replace(tags[1], "")
      let renderedChunk = md.render(chunk)
      return `${tags[0]}${renderedChunk}${tags[1]}`
    }
    return chunk
  })

  const dangerousHTML = htmlArray.join("")
  return dangerousHTML
}
