export function getSubscriberPlan(user) {
  return convertToPlan(getUserMetadata(user, "stripePlan"))
}

export function subscriberHasAccess(user, itemPlan) {
  const subscriberPlan = getSubscriberPlan(user) || "free"
  return getIncludedPlans(subscriberPlan).includes(itemPlan.toLowerCase())
}

export function getIncludedPlans(userPlan) {
  let includedPlans = []

  userPlan = userPlan.toLowerCase()

  includedPlans = []
  switch (userPlan) {
    case "gold":
      includedPlans.push("gold")
      includedPlans.push("silver")
      includedPlans.push("copper")
      includedPlans.push("free")
      break
    case "gm kit":
    case "adventurer":
    case "silver":
      includedPlans.push("silver")
      includedPlans.push("copper")
      includedPlans.push("free")
      break
    case "creator kit":
    case "explorer":
    case "copper":
      includedPlans.push("copper")
      includedPlans.push("free")
      break
    default:
      includedPlans.push("free")
  }

  return includedPlans
}

export function convertToPlan(plan) {
  if (plan) {
    plan = plan.toLowerCase()
    if (["creator kit", "explorer", "copper"].indexOf(plan) !== -1) {
      return "copper"
    } else if (["gm kit", "adventurer", "silver"].indexOf(plan) !== -1) {
      return "silver"
    } else if (["gold"].indexOf(plan) !== -1) {
      return "gold"
    }
  }

  return "free"
}

const prices = {
  free: "Free",
  "player kit": "$3",
  wanderer: "$3",
  "creator kit": "$5",
  explorer: "$5",
  "gm kit": "$10",
  adventurer: "$10",
  copper: "$5",
  silver: "$10",
  gold: "$15",
}

export function getPriceForPlan(plan) {
  return prices[plan.toLowerCase()]
}

export function getUserMetadata(user, field) {
  let userData = undefined
  if (user) {
    userData =
      user[`https://corvidsemporium.com/${field}`] ||
      user[`https://torchlight.press/${field}`]
  }
  return userData
}

export function parseUserMetadata(metafield) {
  return metafield.substring(metafield.lastIndexOf("/") + 1)
}
