import { useEffect } from "react"
import { isBrowser } from "utils"
import { useScript } from "components/hooks/use-script"

export const Rewardful = () => {

  useEffect(() => {
    if (isBrowser) {
      (function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful')
    }
  }, [])

  useScript({
    url: 'https://r.wdfl.co/rw.js',
    dataAttributes: { "rewardful": "06018f" }
  })

  return null
}