import styled, { css } from "styled-components"
import { Grid, GridItem } from "components/ui/grid"

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  margin-bottom: 1.45rem;
  user-select: none;
  font-size: 1rem;
`

export const Hamburger = styled.label`
  cursor: pointer;

  ${props => {
    if (props.condenseAtBreakpoint) {
      return `
        @media (min-width: ${props.breakpoint}px) {
          display: none;
        }
      `
    }
  }}
`

export const HamburgerCheckbox = styled.input.attrs(props => {
  return { type: "checkbox" }
})`
  position: absolute;
  display: none;
`

export const HamburgerLine = styled.div`
  position: relative;
  height: 3px;
  width: 25px;
  background: white;
  margin-bottom: 5px;
  opacity: 100%;

  transition: opacity 0.1s, transform 0.1s;

  ${HamburgerCheckbox}:checked ~ &:nth-of-type(1), ${HamburgerCheckbox}:checked ~ &:nth-of-type(3) {
    z-index: 1;
  }

  ${HamburgerCheckbox}:checked ~ &:nth-of-type(1) {
    transform: translateY(6px) rotate(45deg);
  }

  ${HamburgerCheckbox}:checked ~ &:nth-of-type(2) {
    opacity: 0%;
  }

  ${HamburgerCheckbox}:checked ~ &:nth-of-type(3) {
    transform: translateY(-10px) rotate(-45deg);
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const Menu = styled(GridItem)`
  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    position: unset;
  }
`

export const MenuLeft = styled(Menu)`
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  font-family: "Arimo", sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 0.9em;
  color: rgb(180, 180, 180);

  @media (${props => props.theme.breakpoints.tabletDown}) {
    position: unset;
  }
`

export const MenuCenter = styled(Menu)`
  justify-content: center;
  margin: 0;
  padding: 0;
  font-family: "Merriweather", serif;
  font-weight: bold;
  font-style: italic;
  font-size: 14px;
  color: white;

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    font-size: 20px;
  }
`

export const MenuRight = styled(Menu)`
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  font-family: "Arimo", sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 0.9em;
  color: rgb(180, 180, 180);
`

export const MenuDivider = styled.div`
  height: 20px;
  width: 2px;
  background: white;
  flex-shrink: 0;

  ${props => {
    if (props.$removeFromDesktop) {
      return `
        @media (${props => props.theme.breakpoints.tabletUp}) {
          display: none;
        }
        @media (min-width: 900px) {
          display: none;
        }
      `
    }
    if (props.$removeFromMobile) {
      return `
        @media (${props => props.theme.breakpoints.tabletDown}) 
          display: none;
        }
        @media (max-width: 900px) {
          display: none;
        }
      `
    }
  }}
`

export const Links = styled(Grid)`
  padding: 0;

  a:hover,
  svg:hover,
  div:hover {
    color: ${props => props.theme.colors.yellow};
    cursor: pointer;
  }

  & > * {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    position: unset;
  }

  ${props => {
    if (props.condenseAtBreakpoint) {
      return css`
        @media (max-width: ${props.breakpoint}px) {
          position: absolute;
          flex-direction: column;
          align-items: flex-start;
          display: ${props.visible ? "flex" : "none"};
          top: calc(100% + 18px);
          background: rgb(0, 0, 0);
          right: 0;
          height: 100vh;
          justify-content: flex-start;

          ${MenuDivider} {
            display: none;
          }

          & > * {
            width: 100%;
            margin: 0;
          }

          & > a,
          & > span {
            padding: 15px 75px 15px 50px;
          }
        }
      `
    }
  }}
`

export const HamburgerLinks = styled(Links)`
  @media (${props => props.theme.breakpoints.tabletDown}) {
    font-size: 1.25em;
    transform: ${props => (props.visible ? `` : `translateX(-100%)`)};
    transition: transform 0.3s;

    position: absolute;
    left: 0;
    top: 100%;

    display: flex !important;
    width: 50%;
    min-width: 250px;
    box-shadow: ${props => (props.visible ? `0px 0px 10px 0px black` : ``)};
  }

  @keyframes openNav {
    100% {
      left: 0px;
    }
  }

  @keyframes closeNav {
    0% {
      left: 0px;
    }
    100% {
      left: translateX(-100%);
    }
  }
`
