import { useState, useEffect } from "react"

import { isBrowser } from "utils"

export const useScript = ({
  url,
  async = true,
  useHead = false,
  type = "text/javascript",
  dataAttributes = {},
}) => {
  const [runOnce, setRunOnce] = useState(false)
  useEffect(() => {
    if (!runOnce && isBrowser) {
      setRunOnce(true)

      const script = document.createElement("script")
      script.src = url
      script.async = async
      script.type = type

      Object.entries(dataAttributes).forEach(([name, value]) => {
        script.setAttribute(`data-${name}`, value)
      })

      if (useHead) {
        document.head.appendChild(script)
      } else {
        document.body.appendChild(script)
      }
    }
  }, [runOnce, setRunOnce, url, async, type, useHead, dataAttributes])
}
