import React from "react"
import { ThemeProvider } from "styled-components"

export const Theme = ({ children }) => (
  <ThemeProvider
    theme={{
      colors: {
        yellow: "#ffbb33",
        purple: "#2B0D1C",
        black: "black",
        grey: "#c1c1c1",
        white: "white",
        offWhite: "#fff6e6",
      },
      font: {
        serif: '"Merriweather", serif',
        sans: '"Segoe UI", sans-serif',
      },
      breakpoints: {
        mobileDown: "max-width: 400px",
        mobileUp: "min-width: 400px",
        mobile: 400,
        tabletDown: "max-width: 900px",
        tabletUp: "min-width: 900px",
        tablet: 900,
      },
    }}
  >
    {children}
  </ThemeProvider>
)
