import React from "react"
import { navigate } from "gatsby"
import { Auth0Provider } from "@auth0/auth0-react"

import { isBrowser } from "utils"

export const Auth0ProviderWithHistory = ({ children }) => {
  if (!isBrowser) {
    return null
  }

  const onRedirectCallback = appState => {
    const goTo = appState?.returnTo || window.location.pathname
    //window.location.href = window.location.origin + goTo
    navigate(goTo)
  }

  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}
