import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useCookies } from "react-cookie"
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

const Banner = styled.div`
  display: ${props => props.show ? 'block' : 'none'};
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 30px;
  color: #d1d1d1;
  background: rgb(0,0,0);
`

const Description = styled.div``

const Buttons = styled.div`
  padding: 30px 0 0;
`

const Button = css`
  overflow: hidden;
  position: relative;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  font-family: "Segoe UI", sans-serif;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: uppercase;
  box-shadow: 0 0 5px 0 black;
  color: black;
  background: none;

  margin-right: 20px;
  &:last-of-type {
    margin-right: 0;
  }

  font-size: 1em;
  @media (min-width: 700px) {
    font-size: 1em;
  }
`

const SolidButton = styled.button`
  ${Button}
  color: ${props =>
    props.$dark ? props.theme.colors.white : props.theme.colors.black};
  background: ${props =>
    props.$dark ? props.theme.colors.purple : props.theme.colors.yellow};
`

const OutlineButton = styled.button`
  ${Button}

  color: white;
  background: none;
  border: solid ${props => props.theme.colors.yellow} 2px;
`

const EXPIRY = 31536000 // 365 days as seconds
const COOKIE_KEYS = {
  gdpr: 'gatsby-gdpr',
  fbPixel: 'gatsby-gdpr-facebook-pixel',
  ga: 'gatsby-gdpr-google-analytics'
}

export const GDPRBanner = () => {
  const [cookies, setCookie] = useCookies(Object.values(COOKIE_KEYS))
  const [showBanner, setShowBanner] = useState(undefined === cookies[COOKIE_KEYS.gdpr])
  const [accepted, setAccepted] = useState()

  // initialize gatsby-plugin-gdpr-cookies
  const location = useLocation()
  initializeAndTrack(location) // watches our COOKIE_KEYS values

  useEffect(() => {
    if (accepted === true) {
      setCookie(COOKIE_KEYS.gdpr, true, { maxAge: EXPIRY, sameSite: 'strict' })
      setCookie(COOKIE_KEYS.fbPixel, true, { maxAge: EXPIRY, sameSite: 'strict' })
      setCookie(COOKIE_KEYS.ga, true, { maxAge: EXPIRY, sameSite: 'strict' })
      setShowBanner(false)
    } else if (accepted === false) {
      setCookie(COOKIE_KEYS.gdpr, false, { maxAge: 7, sameSite: 'strict' })
      setCookie(COOKIE_KEYS.fbPixel, false, { maxAge: 7, sameSite: 'strict' })
      setCookie(COOKIE_KEYS.ga, false, { maxAge: 7, sameSite: 'strict' })
      setShowBanner(false)
    }
  }, [accepted, setCookie, setShowBanner])

  return <Banner show={showBanner}>
    <Description>
      <p><b>This website uses cookies</b></p>
      <p>
        We use cookies to personalise content and ads, to provide social media features and to analyze our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.
      </p>
    </Description>
    <Buttons>
      <SolidButton
        onClick={() => {
          setAccepted(true)
        }}
      >
        Accept
      </SolidButton>
      <OutlineButton
        onClick={() => {
          setAccepted(false)
        }}
      >
        Decline
      </OutlineButton>
    </Buttons>
  </Banner>
}