import React from "react"
import { Link as GLink } from "gatsby"

export const Link = props => {
  const { to, children, ...rest } = props

  if ("undefined" === to) {
    return null
  }

  return !to || "undefined" === to ? null : to.startsWith("http") ? (
    <a href={to} target={"_blank"} rel="noreferrer" {...rest}>
      {children}
    </a>
  ) : (
    <GLink to={to} {...rest}>
      {children}
    </GLink>
  )
}
