import { useEffect, useContext } from "react"
import { useAuth0 } from "@auth0/auth0-react"

import { deepFind } from "utils"
import { getUserMetadata, getSubscriberPlan } from "utils/subscription"
import { SnipcartContext } from "components/context/snipcart"

const setCartDiscount = async discountCode => {
  try {
    await window.Snipcart.api.cart.applyDiscount(discountCode)
  } catch (error) {
    // do nothing
  }
}

const removeCartDiscount = async discountCode => {
  try {
    await window.Snipcart.api.cart.removeDiscount(discountCode)
  } catch (error) {
    // do nothing
  }
}

export const SnipcartMetadata = () => {
  const { user, isLoading } = useAuth0()
  const { snipcartReady } = useContext(SnipcartContext)

  useEffect(() => {
    // user is not logged in, remove userId and patron discount
    if (!isLoading && !user && snipcartReady) {
      const removeInitializedListener = window.Snipcart.events.on(
        "snipcart.initialized",
        storeState => {
          window.Snipcart.api.cart.update({
            metadata: {},
          })

          const cartDiscounts = deepFind(
            storeState,
            "cart.discounts.items",
            []
          ).map(discount => discount.code)

          ;["COPPER", "SILVER", "GOLD"].forEach(code => {
            if (cartDiscounts.includes(code)) {
              removeCartDiscount(code)
            }
          })
        }
      )

      return removeInitializedListener

      // user is logged in, add userId and apply patron discount
    } else if (user && snipcartReady) {
      window.Snipcart.api.cart.update({
        metadata: {
          userId: getUserMetadata(user, "uuid"),
        },
      })

      const discountCodeMap = {
        copper: "COPPER",
        silver: "SILVER",
        gold: "GOLD",
      }
      const userTier = getSubscriberPlan(user).toLowerCase()
      const userDiscount = discountCodeMap[userTier]

      const storeState = window.Snipcart.store.getState()
      const cartDiscounts = deepFind(
        storeState,
        "cart.discounts.items",
        []
      ).map(discount => discount.code.toUpperCase())

      if (!cartDiscounts.includes(userDiscount)) {
        setCartDiscount(userDiscount)
      }
    }
  }, [user, isLoading, snipcartReady])

  return null
}
