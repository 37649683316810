import { createGlobalStyle } from "styled-components"

export const StyleGlobal = createGlobalStyle`
  html {
    //overflow: hidden;
  }

  div {
    font-family: ${props => props.theme.font.sans};
    font-size: 1em;
    position: relative;
  }

  ul {
    list-style: disc;
    margin-left: 2em;
  }

  a {
    color: ${props => props.theme.colors.purple};
  }

  p + p {
    margin-top: 10px;
  }

  strong, b {
    font-weight: bold;
  }

  em, i {
    font-style: italic;
  }

  pre {
    white-space: normal;
    background: rgba(255, 255, 255, 0.2);
    padding: 20px;
    margin: 20px 0;

    & + pre {
      margin-top: -20px;
      padding-top: 0;
    }
  }

  h1 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-family: ${props => props.theme.font.serif};
    font-weight: bold;
    font-size: 1.4em;

    @media (min-width: 700px) {
      font-size: 1.75em;
    }
  }

  h2 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-family: ${props => props.theme.font.sans};
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.4em;
  }

  h3 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-family: ${props => props.theme.font.sans};
    font-size: 1.25em;
    font-weight: bold;
  }

  h4 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-family: ${props => props.theme.font.sans};
    font-weight: bold;
    font-style: italic;
    font-size: 1.1em;
  }

  .snip-layout__main-container {
    border-radius: 20px;
  }

  .snip-header {
    border-radius: 20px 20px 0 0;
  }

  .snip-footer {
    border-radius: 0 0 20px 20px;
  }

  #snipcart {
    .snipcart-summary-fees__title {
      display: block;
      margin-top: 25px;
      font-weight: bold;

      .snipcart-summary-fees__discounts-icon {
        font-weight: normal;
        margin-left: 0;

        svg { display: none; }
        .snipcart-tool-tip {
          display: block !important;
          position: relative;
          margin-top: 0;
          background: none;
          box-shadow: none;

          .snipcart-tool-tip__container {
            border: none;
            padding-left: 0;
          }
        }
      }
    }
    
  }

`
