import React, { useRef, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { StaticQuery, graphql } from "gatsby"

import { Grid } from "components/ui/grid"
import * as Styles from "./styles"
import { Dropdown, DropdownLink } from "./links/dropdown"
import { MenuLink } from "./links/link"
import logo from "assets/images/brand/logo.png"
import { Auth0LoginHandler } from "components/controller/auth0-login-handler"
import { useClickOutside } from "components/hooks/use-click"
import { Cart } from "components/ui/cart/cart-icon"

const query = graphql`
  query {
    allPrismicContentContent {
      edges {
        node {
          uid
          tags
          data {
            limit_release
          }
        }
      }
    }
  }
`

const AccountLinks = ({
  $removeFromMobile,
  $removeFromDesktop,
  isAuthenticated,
  setNavOpen,
  logout,
}) => {
  return (
    <>
      {isAuthenticated ? (
        <Dropdown
          title="Patrons"
          {...{ $removeFromMobile, $removeFromDesktop }}
        >
          <DropdownLink
            onClick={() => setNavOpen(false)}
            link="/patron/posts"
            {...{ $removeFromMobile, $removeFromDesktop }}
          >
            Patron Posts
          </DropdownLink>
          <DropdownLink
            onClick={() => setNavOpen(false)}
            link="/patron/downloads"
            {...{ $removeFromMobile, $removeFromDesktop }}
          >
            Downloads
          </DropdownLink>
          <DropdownLink
            onClick={() => setNavOpen(false)}
            link="/patron/billing"
            {...{ $removeFromMobile, $removeFromDesktop }}
          >
            Billing
          </DropdownLink>
        </Dropdown>
      ) : (
        <Dropdown
          title={"Subscribe"}
          {...{ $removeFromMobile, $removeFromDesktop }}
        >
          <DropdownLink
            {...{ $removeFromMobile, $removeFromDesktop }}
            link="/patron/subscribe"
          >
            Become a Patron
          </DropdownLink>
          <DropdownLink
            link="/patron/posts"
            {...{ $removeFromMobile, $removeFromDesktop }}
          >
            Patron Posts
          </DropdownLink>
        </Dropdown>
      )}
      {isAuthenticated ? (
        <MenuLink
          {...{ $removeFromMobile, $removeFromDesktop }}
          onClick={() => {
            setNavOpen(false)
            logout({ returnTo: window.location.origin })
          }}
        >
          Sign Out
        </MenuLink>
      ) : (
        <Auth0LoginHandler>
          <MenuLink
            onClick={() => setNavOpen(false)}
            {...{ $removeFromMobile, $removeFromDesktop }}
          >
            Sign In
          </MenuLink>
        </Auth0LoginHandler>
      )}
    </>
  )
}

const NavBarComponent = props => {
  const [navOpen, setNavOpen] = useState(false)
  const { isAuthenticated, logout } = useAuth0()

  const wrapperRef = useRef(null)
  useClickOutside(wrapperRef, () => setNavOpen(false))

  return (
    <Styles.Wrapper ref={wrapperRef}>
      <Grid
        direction="row"
        nowrap={true}
        css={`
          padding: 10px 20px;
          background: rgb(0, 0, 0);
        `}
      >
        <Styles.MenuCenter size={1} grow={1}>
          <Styles.Links horizontal="center" nowrap={true}>
            <MenuLink
              onClick={() => setNavOpen(false)}
              link="/"
              css={`
                margin-right: 0 !important;
              `}
            >
              <img
                alt=""
                src={logo}
                style={{ maxWidth: "40px", maxHeight: "40px" }}
              />
            </MenuLink>
            <MenuLink onClick={() => setNavOpen(false)} link="/">
              Torchlight Press
            </MenuLink>
          </Styles.Links>
        </Styles.MenuCenter>
      </Grid>
    </Styles.Wrapper>
  )
}

export const NavBar = props => (
  <StaticQuery
    query={query}
    render={data => <NavBarComponent data={data} {...props} />}
  />
)
