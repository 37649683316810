import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { IoIosArrowDropdown } from "react-icons/io"

import { MenuLink } from "./link"
import { useClickOutside } from "components/hooks/use-click"

const Wrapper = styled.div`
  cursor: pointer;
  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    padding-right: 25px;
    position: unset;
  }

  ${props => {
    if (props.$removeFromDesktop) {
      return `
        @media (${props => props.theme.breakpoints.tabletUp}) {
          display: none;
        }
        @media (min-width: 900px) {
          display: none;
        }
      `
    }
    if (props.$removeFromMobile) {
      return `
        @media (${props => props.theme.breakpoints.tabletDown}) 
          display: none;
        }
        @media (max-width: 900px) {
          display: none;
        }
      `
    }
  }}
`

const TopLink = styled.div`
  white-space: nowrap;
  @media (max-width: 900px) {
    padding: 15px 75px 15px 50px;
  }
`

const DropdownIcon = styled(IoIosArrowDropdown)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.25em;
  margin-left: 5px;
`

const StyledDropdown = styled.div`
  visibility: ${props => (props.open ? "" : "hidden")};
  height: ${props => (props.open ? "auto" : 0)};
  overflow: hidden;

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    position: absolute;
    top: 100%;
    background: black;
  }
`

export const Link = styled(MenuLink)`
  display: block;
  color: rgb(180 180 180);
  background: rgb(45 45 45);
  white-space: nowrap;

  @media (max-width: 900px) {
    padding: 15px 75px 15px 50px;
  }

  @media (min-width: 900px) {
    padding: 15px;

    &:last-child {
      padding-bottom: 20px;
    }
  }
`

export const DropdownLink = props => {
  return <Link {...props} />
}

export const Dropdown = ({
  title,
  $removeFromMobile,
  $removeFromDesktop,
  children,
}) => {
  const [wasClicked, setWasClicked] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [triggerShow, setTriggerShow] = useState(0)
  const [show, setShow] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  const wrapperRef = useRef(null)
  useClickOutside(wrapperRef, () => {
    setShow(false)
    setWasClicked(false)
  })

  useEffect(() => {
    if (!wasClicked && !isHovering) {
      const timeoutId = setTimeout(() => {
        setShow(false)
      }, 500)
      return () => clearTimeout(timeoutId)
    }
  }, [wasClicked, isHovering, triggerShow])

  return (
    <Wrapper
      ref={wrapperRef}
      onTouchStart={() => {
        setIsMobile(true)
      }}
      onMouseEnter={() => {
        if (!isMobile) {
          setShow(true)
          setIsHovering(true)
        }
      }}
      onMouseLeave={() => {
        setWasClicked(false)
        setIsHovering(false)
        setTriggerShow(triggerShow + 1)
      }}
      {...{ $removeFromMobile, $removeFromDesktop }}
    >
      <TopLink
        onMouseEnter={e => e.stopPropagation()}
        onClick={() => {
          setShow(!show)
          setWasClicked(!show)
        }}
      >
        {title}
        <DropdownIcon />
      </TopLink>
      <StyledDropdown
        open={show}
        onClick={() => {
          setShow(false)
          setWasClicked(!show)
        }}
        onMouseEnter={() => {
          setShow(true)
          setIsHovering(true)
        }}
        onMouseLeave={() => {
          setWasClicked(false)
          setIsHovering(false)
          setTriggerShow(triggerShow + 1)
        }}
      >
        {children}
      </StyledDropdown>
    </Wrapper>
  )
}
