import React from "react"

import { useScript } from "components/hooks/use-script"

export const Snipcart = () => {
  useScript({
    url: "https://cdn.snipcart.com/themes/v3.3.0/default/snipcart.js",
  })
  return (
    <div
      id="snipcart"
      data-api-key={`${process.env.GATSBY_SNIPCART_API_KEY}`}
      data-config-add-product-behavior="none"
      data-config-modal-style="side"
      hidden
    ></div>
  )
}
